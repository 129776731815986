import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  defaultDataIdFromObject,
  split
} from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/link-context';
import { OAuthError, useAuth0 } from '@auth0/auth0-react';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { createClient } from 'graphql-ws';
import { t } from 'i18next';
import { includes } from 'lodash';
import { JSX, PropsWithChildren, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { graphqlApiConfig } from '../../configs';
import { getRedirectFullPath } from '../../utilities';
import { ErrorPage } from '../1-pages';
import { PageWrapper } from '../2-templates';

type ApolloClientProviderProps = PropsWithChildren;

export const ApolloClientProvider = ({ children }: ApolloClientProviderProps): JSX.Element => {
  const { getAccessTokenSilently } = useAuth0();
  const [tokenFetchError, setTokenFetchError] = useState<OAuthError | undefined>(undefined);
  const apolloClient = useRef<ApolloClient<NormalizedCacheObject>>();
  const timeoutLink = new ApolloLinkTimeout(graphqlApiConfig.defaultTimeout);
  const httpLink = new HttpLink({ uri: `${graphqlApiConfig.host}${graphqlApiConfig.graphqlEndpoint}` });
  const timeoutHttpLink = timeoutLink.concat(httpLink);
  const routerLocation = useLocation();
  const fullPath = getRedirectFullPath(routerLocation);

  const fetchToken = async (): Promise<string | void> => {
    try {
      return await getAccessTokenSilently();
    } catch (error: unknown) {
      const typedError = error as OAuthError;
      setTokenFetchError(typedError);
    }
  };

  const authHttpLink = setContext(async (_, { headers, ...rest }) => {
    const token = await fetchToken();
    if (!token) return { headers, ...rest };
    return { ...rest, headers: { ...headers, authorization: `Bearer ${token}` } };
  });

  // @ts-expect-error The expected type comes from property 'link' which is declared here on type 'ApolloClientOptions<NormalizedCacheObject>'
  const fullHttpLink = authHttpLink.concat(timeoutHttpLink);

  const wsLink = new GraphQLWsLink(
    createClient({
      url: `${graphqlApiConfig.wsHost}${graphqlApiConfig.graphqlEndpoint}`,
      connectionParams: async () => {
        const connectToken = await fetchToken();
        return { headers: { Authorization: connectToken ? `Bearer ${connectToken}` : '' } };
      }
    })
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    // Apollo issue: Typescript thinks the type ApolloLink from '@apollo/client'
    // is different from the type ApolloLink from '@apollo/link-context'.
    // @ts-expect-error namespace conflict
    fullHttpLink
  );

  if (!apolloClient.current) {
    apolloClient.current = new ApolloClient({
      link: splitLink,
      cache: new InMemoryCache({
        dataIdFromObject(responseObject) {
          if (
            responseObject.__typename === 'DeviceOperationImageUrl' &&
            includes(responseObject.url as string, 'thumb')
          ) {
            return `DeviceOperationImageUrl:${responseObject.id}-thumb`;
          }
          return defaultDataIdFromObject(responseObject);
        },
        typePolicies: {
          // The fields listed below do not use `id` as their primary key. We need to tell Apollo for the non-`id`
          // primary keys.
          Timezone: { keyFields: ['name'] },
          TimezoneInfo: { keyFields: ['name'] },
          Customer: { keyFields: ['companyId'] },
          ServiceProvider: { keyFields: ['companyId'] },
          CustomerCurrentPerformance: { keyFields: ['customerId'] },
          SiteCurrentPerformance: { keyFields: ['siteId'] }
        }
      })
    });
  }

  if (tokenFetchError) {
    return (
      <PageWrapper loginRequired={false} loginRedirectUri={fullPath} hasNavigation={false}>
        <ErrorPage
          title={t('errorPage.errorOccurred')}
          titleEmphasized={t('errorPage.error')}
          message={tokenFetchError.message}
        />
      </PageWrapper>
    );
  }

  return <ApolloProvider client={apolloClient.current}>{children}</ApolloProvider>;
};
