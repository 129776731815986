import { useQuery } from '@apollo/client';
import { GridPaginationModel } from '@mui/x-data-grid';
import { get, toNumber, toString } from 'lodash';
import qs from 'qs';
import { JSX, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import { CompaniesAdminSearchParameters, companiesAdminStatesSchema } from './companies-admin-states-schema';
import { companiesAdminDataGridColumns, generateCompaniesAdminRows } from './data-grid-configurations';
import { DEFAULT_GET_ADMIN_COMPANIES_SORT_BY, DEFAULT_PAGE_SIZE } from '../../../constants';
import { QUERY_GET_ADMIN_COMPANIES } from '../../../services/queries';
import {
  calculatePaginationEndRow,
  calculatePaginationPageCount,
  calculatePaginationStartRow,
  filterValidUrlFields
} from '../../../utilities';
import { AdminOverviewMainSectionWrapper } from '../../2-templates';
import { AdminAddActionButton, AdminPageTitle, PaginationBar, RSDataGrid } from '../../4-features';
import { UserTimezoneContext } from '../../contexts';
import { ErrorPage } from '../error-page';

export const CompaniesAdminPage = (): JSX.Element => {
  const { t } = useTranslation();
  const routerLocation = useLocation();
  const { userTimezone } = useContext(UserTimezoneContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 1, pageSize: DEFAULT_PAGE_SIZE });
  const { data, loading, error } = useQuery(QUERY_GET_ADMIN_COMPANIES, {
    variables: {
      offset: (paginationModel.page - 1) * DEFAULT_PAGE_SIZE,
      limit: DEFAULT_PAGE_SIZE,
      orderBy: DEFAULT_GET_ADMIN_COMPANIES_SORT_BY
    }
  });

  const searchParameters = qs.parse(searchParams.toString());
  const validUrlFields = filterValidUrlFields<CompaniesAdminSearchParameters>(
    searchParameters,
    companiesAdminStatesSchema
  );

  useEffect(() => {
    // Set page
    const pageNumber = toNumber(get(validUrlFields, 'page')) || 1;
    if (paginationModel.page !== pageNumber) {
      setPaginationModel((prevModel) => ({ ...prevModel, page: pageNumber }));
    }
  }, [routerLocation.search]);

  if (error) {
    return (
      <ErrorPage
        titleEmphasized={t('apolloErrorPage.errorCode')}
        title={t('apolloErrorPage.errorTitle')}
        message={error.message}
      />
    );
  }

  const companiesCount = data?.companiesAggregate.aggregate?.count;

  return (
    <AdminOverviewMainSectionWrapper
      title={
        <AdminPageTitle
          titleName={t('companiesAdminPage.title')}
          actions={
            <AdminAddActionButton
              titleName={t('companiesAdminPage.addCompany')}
              disabled={true}
              data-testid="companies-admin-page-add-company"
            />
          }
        />
      }
      filter={<div>filter placeholder</div>}
      data-testid="companies-admin-page-wrapper"
    >
      <section className="companies-admin-page" data-testid="companies-admin-page">
        <div className="companies-admin-page__data-grid-container">
          <RSDataGrid
            columns={companiesAdminDataGridColumns}
            rows={generateCompaniesAdminRows(data?.companies, userTimezone)}
            loading={loading}
            data-testid="companies-admin-page-data-grid"
          />
        </div>
        <div className="companies-admin-page__pagination">
          <PaginationBar
            isLoading={loading}
            startRow={calculatePaginationStartRow(paginationModel.page, DEFAULT_PAGE_SIZE)}
            endRow={calculatePaginationEndRow(paginationModel.page, DEFAULT_PAGE_SIZE, companiesCount)}
            rowCount={companiesCount}
            pageCount={calculatePaginationPageCount(companiesCount)}
            onChange={(_event, page) => {
              const originalSearchParamsObject = qs.parse(searchParams.toString());
              setSearchParams(
                qs.stringify({ ...originalSearchParamsObject, page: toString(page) }, { arrayFormat: 'brackets' })
              );
            }}
            page={paginationModel.page}
          />
        </div>
      </section>
    </AdminOverviewMainSectionWrapper>
  );
};
