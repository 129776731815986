import { JSX } from 'react';

import { AdminOverviewMainSectionWrapper } from '../../2-templates';
import { AdminAddActionButton, AdminPageTitle } from '../../4-features';

export const UsersAdminPage = (): JSX.Element => {
  return (
    <AdminOverviewMainSectionWrapper
      title={
        <AdminPageTitle
          titleName="Users"
          actions={
            <AdminAddActionButton titleName="Add user" disabled={true} data-testid="users-admin-page-add-user" />
          }
        />
      }
      filter={<div>filter placeholder</div>}
      data-testid="users-admin-page-wrapper"
    >
      <section className="users-admin-page" data-testid="users-admin-page">
        Placeholder for the data grid section
      </section>
    </AdminOverviewMainSectionWrapper>
  );
};
