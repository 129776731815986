import { useApolloClient } from '@apollo/client';
import { InputLabel } from '@mui/material';
import { JSX, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ReloadIcon from '../../../assets/icons/reload.svg?react';
import { Loading, RSButton } from '../../5-elements';

export const ReloadData = (): JSX.Element => {
  const { t } = useTranslation();
  const { resetStore } = useApolloClient();
  const [showOverlay, setShowOverlay] = useState(false);

  const handleReload = () => {
    setShowOverlay(true);
    resetStore();
    setTimeout(() => {
      setShowOverlay(false);
    }, 500);
  };

  return (
    <div className="reload-data" data-testid="reload-data" onClick={() => handleReload()}>
      <div
        id="reload-data-overlay"
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255,255,255,0.5)',
          zIndex: 2,
          cursor: 'none',
          display: showOverlay ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Loading />
      </div>
      <InputLabel shrink={true} className="reload-data__input-label" data-testid="reload-data-input-label">
        {t('reloadData.label')}
      </InputLabel>
      <RSButton extraClassNames={['reload-data__button']} data-testid="reload-data-button">
        <div className="reload-data__icon-wrapper">
          <ReloadIcon />
        </div>
      </RSButton>
    </div>
  );
};
